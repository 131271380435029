.create-payment-form {
    width: 90% !important;
    margin: auto;
    background: black;
    border-radius: 15px;
}
.create-payment-form form {
    display: flex;
    padding: 0 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.create-payment-form form .input {
    width: 100%;
    margin-bottom: 20px;
    padding: 25px;
    font-size: 1.9rem;
    font-weight: 400;
    color: white;
    border: none;
    background: transparent;
    border-bottom: 1px solid white;
    border-radius: 5px;
}

.link {
    word-break: break-all;
    border-radius: 8px;
    font-size: large;
    padding: 0 20px;
}

.spinLoading {
    border: 4.5px solid rgba(199, 199, 199, 0.7);
    height: 40px;
    display: inline-block;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    border-radius: 100%;
    width: 40px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.tooltiptip {
    position: relative;
}

.tooltiptip .tooltiptext {
    visibility: hidden;
    background-color: rgb(51, 51, 51);
    color: #fff;
    font-weight: 400;
    text-align: center;
    border-radius: 6px;
    padding: 4px 10px;
    position: absolute;
    font-size: 18px;
    z-index: 1;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltiptip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: rgb(51, 51, 51) transparent transparent transparent;
}

.tooltiptip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* use media query */

@media only screen and (max-width: 768px) {
    .create-payment-form {
        width: 100%;
    }
    .create-payment-form form input,
    textarea {
        width: 100%;
    }
}
@media only screen and (max-width: 1024px) {
    .create-payment-form {
        width: 90%;
        margin: auto;
    }
    .create-payment-form form input,
    textarea {
        width: 90%;
        margin: 25px 0;
    }
}
