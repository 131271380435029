.footer{
    height: 90vh;
    width: 100vw;
    background-color: black;
    display: flex;
    align-items: center;
}
.idea{
    font-size: 3.5vw;
    color:white ;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.link{
    text-decoration: none;
}
.link h1{
    font-size: 3.5vw;
    color:white ;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
}
.link-hover{
    border-bottom: 1px solid transparent;
    border-bottom: 0%;
    transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.link-hover:hover{
    letter-spacing: 0.2rem;
    border-bottom:1px solid white;
}
.mail-footer{
    cursor: pointer;
    color:white ;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border-bottom: white;
    transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.mail-footer:hover{
    letter-spacing: 0.2rem;

}
address p{
    color: white;
}
.social-icon{
    color: rgb(138, 128, 128);
    font-size: 25px;
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
  }
  .social-icon:hover{
    color: white;
    transform-origin: start;
    transform: scale(1.5);
  }
  .display-false{
      display: none;
  }



  @media only screen and (max-width: 767px){
    .footer{
        height: auto;
    }
    .display-false{
        display: block;
    }
    .display-true{
        display: none;
    }
    .idea{
        font-size: 8vw;
    }
    .link h1{
        font-size: 6.5vw;
    }
  }