.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    position: relative;
    overflow: hidden;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .showcase-container{
    width:90vw;
    height: 62vh;
    margin: auto;
  }
  .product-intro{
    font-family: 'Ubuntu', sans-serif;
    position: absolute;
    top: 0%;
    left: -60%;
    z-index: 999;
    background-color: #0f141ede;
    width: 60%;
    height: 100%;
    visibility: hidden;
    transition: all 1.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .product-tittle{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1.5vw;
    -webkit-text-stroke: 1px #fff;
    color: transparent;
  }
  .stroke{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 4vw;
    -webkit-text-stroke: 1px #000;
    color: transparent;
  }
  .n-stroke{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 4vw;
    color: #000;
  }

  .product-container:hover .product-intro{
    visibility: visible;
    left: 0;
  }

  .product{
    position: absolute;
    top: 0;
    left: 0;
  }
  .swiper-pagination-fraction{
    bottom: 0;
  }
  .swiper-pagination-bullet{
    width:25px;
    height: 5px;
    border-radius: 5px;
    background-color: #0F141E;
  }
  .show-false{
    display: none;
  }


  @media only screen and (min-width: 1500px){
    .showcase-container{
      width:80vw;
    }
  }
  @media only screen and (max-width: 1600px){
    .showcase-container{
      height:65vh;
    }
  }
  @media only screen and (max-width: 1399px){
  .show-true{
    display: none;
  }
  .show-false{
    display: block;
  }
  }
  @media only screen and (max-width: 767px){
.stroke,.n-stroke{
  font-size: 2.7rem;
}
  }