.about-intro{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 3.5vw;
    letter-spacing: -0.2rem;
    color: #000;
}
.about-des{
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    font-size: 1.7rem;
    color: #000;
}
.about-des-2{
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    font-size: 1.7rem;
    color: #000;
}
.achivement-title{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 5rem;
    letter-spacing: -0.2rem;
    color: #000;
}
.achivement-des{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    color: #000;
}

@media only screen and (max-width: 767px){
    .about-intro{
        font-size: 2.5rem;
    }
    .about-des{
        font-size: 1.2rem;
    }
    .about-des-2{
        font-size: 1.2rem;
    }
}