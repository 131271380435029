.banner{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-false{
    display: none;
}
#video-container{
    width: 350px;
    height: 350px;
    position: relative;
    border-radius: 50%;
    margin: auto;
    /* border: 1px solid black; */
    overflow: hidden;
}
#video-container video{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
}
.w-90{
    width: 80%;
    margin: auto;
}
.frame-container{
    position: relative;
    border-radius: 10px;
    height: 50vh;
}
.hero-text{
    position: absolute;
    top:30%;
    left: 0;
}
.hero-text-middle{
    position: absolute;
    top:30%;
    left: 25%;
}
.hero-text-last{
    position: absolute;
    top:30%;
    left: 20%;
}
.hero-text h1{
    cursor:pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 5.5em;
    -webkit-text-stroke: 1px #000;
    color: transparent;
}
.hero-text-mobile{
    cursor:pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 3rem;
    color: black;
    border-bottom: 1px solid black;
}
.hero-text-middle h1{
    cursor:pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 5.5em;
    -webkit-text-stroke: 1px #000;
    color: transparent;
    transition: color 0.3s, -webkit-text-stroke 0.3s;
}
.hero-text-middle-mobile{
    cursor:pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 3rem;
    color: black;
    border-bottom: 1px solid black;
}
.hero-text-last h1{
    cursor:pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 5.5em;
    -webkit-text-stroke: 1px #000;
    color: transparent;
    transition: color 0.3s, -webkit-text-stroke 0.3s;
}
.hero-text-last-mobile{
    cursor:pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 3rem;
    color: black;
    border-bottom: 1px solid black;
}
.hero-text h1:hover{
    color: rgba(0, 0, 0, 0.616);
    -webkit-text-stroke: 1px black;
}
.hero-text-mobile h1:hover{
    color: rgba(0, 0, 0, 0.616);
    -webkit-text-stroke: 1px black;
    border-bottom: 1px solid black;
}
.hero-text-middle h1:hover{
    color: rgba(0, 0, 0, 0.616);
    -webkit-text-stroke: 1px black;
}
.hero-text-last h1:hover{
    color: rgba(0, 0, 0, 0.616);
    -webkit-text-stroke: 1px black;
}
#punch-line{
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.7em;
    font-weight: 400;
}
#punch-line-mobile{
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
}
.hidden{
    visibility: hidden;
    font-size: 1.7em;
}
.frame-container video{
    position: absolute;
    top: 50%;
    left: 40%;
    width: 20px;
    height: 20px;
    border-radius:50%;
    object-fit: cover;
    opacity: 0;
    /* top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.07; */ 
    transition: all 0.5s;
}
.frame-container:hover video{
    opacity: 1;
    cursor: pointer;
    width: 103%;
    height: 103%;
    border-radius: 10px;
    top: 0;
    left: 0;
}
.brand-text{
    font-family: 'Ubuntu', sans-serif;
    font-size: 2em;
}


@media only screen and (max-width: 1399px){
    .d-false{
        display: block;
    }
    .d-true{
        display: none;
    }
  }
@media only screen and (max-width: 767px){
    #video-container{
        width: 65vw;
        height: 65vw;
        border-radius: 50%;
        overflow: hidden;
    }
    .brand-text{
        font-family: 'Ubuntu', sans-serif;
        font-size: 1.5em;
    }
  }