.brand-vid{
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.brand-vid video{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
}