.App {
  text-align: center;
}

.loader{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.loader-title{
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.loader2-title{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loader-title h1{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 7.5vh;
  -webkit-text-stroke: 1.3px #000;
    color: transparent;
}
