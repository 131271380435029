.payment-form{
    width: 90%!important;
    margin: auto;
    background: black;
    border-radius: 15px;
}
.payment-form form{
    display: flex;
    padding: 0 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.payment-form form .input{
    width: 100%;
    margin-bottom: 20px;
    padding: 25px;
    font-size: 1.9rem;
    font-weight: 400;
    color: white;
    border: none;
    background: transparent;
    border-bottom: 1px solid white;
    border-radius: 5px;
}

/* use media query */

@media only screen and (max-width: 768px)  {
    .payment-form{
        width: 100%;
    }
    .payment-form form input,textarea{
        width: 100%;
        
    }
}
@media only screen and (max-width: 1024px)  {
    .payment-form{
        width: 90%;
        margin: auto;
    }
    .payment-form form input,textarea{
        width: 90%;
        margin: 25px 0;
    }
}