.logoImage {
  width: 80%;
}

.logo {
  transition: 0.3s ease;
}

.Ubuntu:hover .logo {
  letter-spacing: 0.05rem;
}

.Ubuntu {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}

.logo-1 {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 120px;
  height: 120px;
}

.cantarell {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
}

.nav-link {
  font-size: 4.7vw;
  text-decoration: none;
  color: black;
  font-weight: 400;
  letter-spacing: -0.05rem;
  transition: 0.3s;
}

.nav-link:hover {
  letter-spacing: 0.2rem;
  color: black;
}

.menu {
  line-height: 5vw;
}

.social-menu {
  display: flex;
  flex-direction: column;
}

.nav-social-link {
  font-size: 1rem;
  text-decoration: none;
  color: black;
  font-weight: 500;
  transition: 0.3s;
  margin-top: 10px;
  cursor: pointer;
}

.nav-social-link:hover {
  letter-spacing: 0.1rem;
  color: black;
}

.mail {
  transition: 0.3s;
  cursor: pointer;
}

.mail:hover {
  letter-spacing: 0.1rem;
}

.offcanvas {
  width: 50vw;
  border: none;
}

.wrapper {
  height: 50px;
}

/* media query */

@media only screen and (max-width: 1159px) {
  .offcanvas {
    width: 70vw;
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .w-10 {
    width: 25%;
  }

  .wrapper {
    height: auto;
  }

  .logo {
    display: none;
  }

  .logoImage {
    width: 100%;
  }

  .logo-1 {
    margin-left: 15px;
    width: 70px;
    height: 30px;
  }

  .display-none {
    display: none;
  }

  .offcanvas {
    width: 100vw;
    border: none;
  }

  .nav-link {
    font-size: 5.7vw;
  }
}