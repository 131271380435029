.contact-form{
    width: 90%!important;
    margin: auto;
    background: black;
    border-radius: 15px;
}
.contact-form form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-form form input,textarea{
    width: 90%;
    margin-bottom: 20px;
    padding: 25px;
    font-size: 1.9rem;
    font-weight: 400;
    color: white;
    border: none;
    background: transparent;
    border-bottom: 1px solid white;
    border-radius: 5px;
}
.contact-form form textarea{
    color: white;
}


/* use media query */

@media only screen and (max-width: 768px)  {
    .contact-form{
        width: 100%;
    }
    .contact-form form input,textarea{
        width: 100%;
        
    }
}
@media only screen and (max-width: 1024px)  {
    .contact-form{
        width: 90%;
        margin: auto;
    }
    .contact-form form input,textarea{
        width: 90%;
        margin: 25px 0;
    }
}