.work-title{
    font-family: 'Ubuntu', sans-serif;
    font-weight: 600;
    color: #000;
    font-size: 1.5rem;
    line-height: 3rem;
}
.work-title-sub{
    font-family: 'Ubuntu', sans-serif;
    font-weight: 200;
    color: #000;
}

@media only screen and (max-width: 767px){
    .work-title{
      font-size: 1.3rem;
    }
}
