.b-stroke{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 3.5vw;
    letter-spacing: -0.1rem;
    color: black;
  }
 

  .swiper {
    width: 100%;
    height: 105% !important;
  }
  
  .swiper-slide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .social-reels{
      height: auto;
      width: 100%;
  }
  .des-text{
      font-weight: 500;
  }
  .mobile{
    display: none;
  }

  @media only screen and (max-width: 767px){
    .mobile{
      display: block;
    }
    .n-mobile{
      display: none;
    }
    .b-stroke{
      font-size: 2.7rem;
    }
}